import _ from 'lodash'
import moment from 'moment'

export default function updateTicketRecord(eventId, ticketId) {
  const jsonData = localStorage.getItem('record')
  const data = jsonData !== null ? JSON.parse(jsonData) : {}
  const ticketRecord = _.get(data, `${eventId}.${ticketId}`, [])
  ticketRecord.push(moment().format())
  const updatedData = _.set(data, `${eventId}.${ticketId}`, ticketRecord)

  localStorage.setItem('record', JSON.stringify(updatedData))
}
