import axios from 'axios'
import _ from 'lodash'

const key = process.env.REACT_APP_GOOGLE_KEY
const sheetId = process.env.REACT_APP_SHEET_ID
const baseApi = process.env.REACT_APP_API_URL
const sheetDBApi = process.env.REACT_APP_SHEETDB_API

function getGoogleApi(range) {
  return `${baseApi}/${sheetId}/values/${range}?key=${key}`
}

async function getAllEventId () {
  try {
    const api = getGoogleApi('!A:A')
    const response = await axios.get(api)
    const data = response.data
    return _.flatten(_.get(data, 'values', [])).map(k => String(k))
  } catch (e) {
    return []
  }
}

async function getEventData (index) {
  try {
    const api = getGoogleApi(`B${index + 1}`)
    const response = await axios.get(api)
    const data = response.data
    return _.get(data, 'values', [['{}']])
  } catch (e) {
    return [['{}']]
  }
}

async function sheetDBCreate (data) {
  try {
    await axios.post(sheetDBApi, { data })
  } catch (e) {
    console.log(e)
  }
}

async function sheetDBUpdate (data) {
  try {
    const promises = _.map(data, d => {
      return axios.put(`${sheetDBApi}/eventId/${d.eventId}`, { data })
    })
    await Promise.all(promises)
  } catch (e) {
    console.log(e)
  }
}

async function getEventAttendance(eventId) {
  try {
    const allEventId = await getAllEventId()
    const index = _.findIndex(allEventId, id => id === String(eventId))

    if (index === -1) return 0

    const eventData = await getEventData(index)
    const data = _.get(_.flatten(eventData), 0)

    if (!data) return 0

    const value = JSON.parse(data)
    const result = _.keys(value).length
    return result
  } catch (e) {
    return 0
  }
}

async function updateServerRecord () {
  try {
    const allEventId = await getAllEventId()
    const jsonData = localStorage.getItem('record')
    const recordData = jsonData !== null ? JSON.parse(jsonData) : {}
    const localEventIds = _.keys(recordData).map(k => String(k))
    const createList = []
    const updateList = []
    for (const k of localEventIds) {
      const data = _.get(recordData, k, {})
      if (!allEventId.includes(k)) {
        createList.push({ eventId: k, data: JSON.stringify(data)})
      } else {
        const index = _.findIndex(allEventId, id => id === k)
        const eventData = await getEventData(index)
        const serverRecord = JSON.parse(_.get(_.flatten(eventData), 0))
        if (!_.isEqual(serverRecord, data)) {
          const updatedData = _.mergeWith(serverRecord, data, (o, s) => _.union(o, s))
          updateList.push({ eventId: k, data: JSON.stringify(updatedData) })
        }
      }
    }
    if (!_.isEmpty(createList)) {
      await sheetDBCreate(createList)
    }
    if (!_.isEmpty(updateList)) {
      await sheetDBUpdate(updateList)
    }
  } catch (e) {
    console.log(e)
  }
}

export { getEventAttendance, updateServerRecord }
