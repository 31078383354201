import React from 'react';

import { QrReader } from 'react-qr-reader';

import './theme/scanner.css';

function Scanner (props) {
  const { onSuccess = () => {} } = props

  return (
    <div className='conatiner'>
      <QrReader
        constraints={{ facingMode: 'environment' }}
        onResult={(result, error) => {
          if (result && result.text) {
            onSuccess(result.text)
          }
        }}
        containerStyle={{ width: '100%' }}
        videoContainerStyle={{ width: '100%', paddingTop: '100vh', backgroundColor: '#000000' }}
        videoStyle={{ width: '100%' }}
      />
    </div>
  );
}

export default Scanner;
