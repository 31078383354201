import React from 'react';

import { IconButton, Typography } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings';
import ClearIcon from '@mui/icons-material/Clear';

import Main from './Main';
import Setting from './Setting';
import './theme/index.css';

function App() {
  const [setting, setSetting] = React.useState(false)
  const [events, setEvents] = React.useState()

  return (
    <div>
      <div className='headerButton'>
        <Typography sx={{ padding: '10px' }}>{process.env.REACT_APP_VERSION}</Typography>
        <IconButton color="primary" component="label" onClick={() => setSetting(!setting)}>
          {
            setting
              ? <ClearIcon />
              : <SettingsIcon />
          }
        </IconButton>
      </div>
      {
        setting
          ? <Setting events={events} />
          : <Main events={events} setEvents={setEvents} />
      }
    </div>
  );
}

export default App;
