import axios from 'axios'
import _ from 'lodash'

export default async function getEvents() {
  try {
    const response = await axios.get('https://muxic.azurewebsites.net/api/ticket/events')
    const result = _.flatten(_.values(response.data))
    return result
  } catch (e) {
    throw e
  }
}
