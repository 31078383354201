import React from 'react';

import { Button, Card, CardContent, CardActions, Typography } from '@mui/material';
import _ from 'lodash'

import { getEventAttendance } from './lib/googleSheet'

function AttendanceRecord(props) {
  const { event, onBack } = props
  const [local, setLocal] = React.useState(0)
  const [server, setServer] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    getServerRecord()
    getLocalRecord()
  }, [event])

  const getServerRecord = async () => {
    setLoading(true)
    const result = await getEventAttendance(event?.id)
    setServer(result)
    setLoading(false)
  }

  const getLocalRecord = () => {
    const jsonData = localStorage.getItem('record')
    const recordData = jsonData !== null ? JSON.parse(jsonData) : {}
    const result = _.get(recordData, event?.id, {})
    const value = _.keys(result).length
    setLocal(value)
  }

  return (
    <Card sx={{ width: '100%', height: '100vh' }}>
      <CardContent
        sx={{
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {
          !_.isEqual(event.title, event.title_chinese) && (
            <Typography variant="h6" component="div">
              {event.title_chinese}
            </Typography>
          )
        }
        <Typography sx={{ mb: 1.5 }} variant="h6" component="div">
          {event?.title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant="h5" component="div">
          Venue Attendance / 入場人數:
        </Typography>
        <Typography variant="h6" component="div">
          Local Record
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant="h4" component="div">
          {local}
        </Typography>
        <Typography variant="h6" component="div">
          Server Record
        </Typography>
        <Typography sx={{ mb: 1.5 }} variant="h4" component="div">
          {loading ? 'Loading...' : server}
        </Typography>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button variant="contained" onClick={async () => {
            getLocalRecord()
            await getServerRecord()
          }} color="secondary">Refresh</Button>
          <Button variant="contained" onClick={onBack}>Back</Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default AttendanceRecord;
