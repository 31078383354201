import CryptoJS from 'crypto-js';
import _ from 'lodash'

export default function decryptMsg(message, events) {
  const result = _.split(message, ',')
  if (result.length !== 3) return false

  const eventId = _.split(result[0], ':')
  const ticketId = _.split(result[1], ':')
  const sign = result[2]

  if (eventId.length !== 2 || eventId[0] !== 'event' || ticketId.length !== 2 || ticketId[0] !== 'ticketid') return false

  const event = _.find(events?.data, e => String(e.id) === eventId[1])

  try {
    const iv = CryptoJS.enc.Utf8.parse((new Array(16).fill(String.fromCharCode(0))).join(''))
    const ciphertext = CryptoJS.enc.Base64.parse(sign)
    const key = CryptoJS.enc.Utf8.parse(_.padStart(`Muxic${eventId[1]}`, 16, '0'))
    const plaintextData = CryptoJS.AES.decrypt({ ciphertext }, key, { iv })
    const plaintext = plaintextData.toString(CryptoJS.enc.Latin1)
    const regexResult = plaintext.replace(plaintext.split('{"isMember":')[0], '')

    if (!regexResult) return false
    const data = JSON.parse(regexResult)
    const otherInfo = _.get(data, 'otherInfo')

    if (_.get(data, 'otherInfo')) {
      try {
        const otherInfoData = JSON.parse(otherInfo)
        data.otherInfo = otherInfoData
      } catch (error) {
        data.otherInfo = {}
      }
    }

    return { event, data, ticketId: ticketId[1] }
  } catch (error) {
    return false
  }
}