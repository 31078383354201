import React from 'react';

import { Button, Card, CardContent, CardActions, Typography, ListItem, ListItemText } from '@mui/material';
import { FixedSizeList } from 'react-window'
import VerifiedIcon from '@mui/icons-material/Verified';
import { green, yellow } from '@mui/material/colors';
import _ from 'lodash'
import moment from 'moment'

import decryptMsg from './lib/decryptMsg'
import updateTicketRecord from './lib/record'
import sound from './lib/alarm.wav'

function Result(props) {
  const { data, events, onNext } = props
  const result = decryptMsg(data, events)
  const offset = moment().utcOffset()
  const jsonData = localStorage.getItem('record')
  const [show, setShow] = React.useState(false)
  const recordData = jsonData !== null ? JSON.parse(jsonData) : {}
  const audio = new Audio(sound)

  if (!result) {
    return (
      <Card sx={{ width: '100%', height: '100vh' }}>
        <CardContent
          sx={{
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5" component="div" color="error">
            Decode failed
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button variant="contained" onClick={onNext}>Retry</Button>
        </CardActions>
      </Card>
    )
  }
  const event = result.event
  const customer = result.data
  const ticketId = result.ticketId
  const ticketRecord = _.reverse(_.get(recordData, `${event?.id}.${ticketId}`, []))

  const renderRow = (props) => {
    const { index, style } = props;

    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemText secondary={moment(ticketRecord[index]).format('DD/MM/YYYY HH:mm:ss')} />
      </ListItem>
    );
  }

  if (ticketRecord.length > 0) {
    audio.play()
  }

  return (
    <Card sx={{ width: '100%', height: '100vh' }}>
      <CardContent
        sx={{
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <VerifiedIcon sx={{ fontSize: 150, color: ticketRecord.length > 0 ? yellow[500] : green[800] }}/>
        <Typography variant="h5" component="div">
          {event?.title}
        </Typography>
        {
          !_.isEqual(event?.title, event?.title_chinese) && (
            <Typography variant="h5" component="div">
              {event?.title_chinese}
            </Typography>
          )
        }
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {moment(event?.eventStart).add(offset, 'm').format('DD/MM/YYYY HH:mm')} - {moment(event?.eventEnd).add(offset, 'm').format('HH:mm')}
        </Typography>
        <Typography variant="h6">
          {customer.isMember ? 'Fringe Club Member/藝穗會會員' : ''}
        </Typography>
        {
          show
          ? (
            <>
              <Typography>
                email: {_.get(customer, 'otherInfo.email')}
              </Typography>
              <Typography>
                phone: {_.get(customer, 'otherInfo.phoneNumber')}
              </Typography>
            </>
          )
          : <Button variant="contained" 
            sx={{ width:'80vw', height: 50}}
            onClick={() => {
            setShow(true)
          }}>Detail</Button>
        }
        <Typography variant="h6" sx={{ mb: 1.5 }}>
          {customer.identity}
        </Typography>
        {
          ticketRecord.length > 0 && (
            <>
              <Typography variant="body2" sx={{ backgroundColor: '#FF0000', padding: '2px', color: '#FFFFFF', fontWeight: 'bold', fontSize: '16px' }}>
                入場記錄 ({ticketRecord.length}) :
              </Typography>
              <FixedSizeList
                height={200}
                width={200}
                itemSize={30}
                itemCount={ticketRecord.length}
                overscanCount={5}
              >
                {renderRow}
              </FixedSizeList>
            </>
          )
        }
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button variant="contained" 
            sx={{ width:'80vw', height: 50}}
            onClick={() => {
            onNext()
            updateTicketRecord(event?.id, ticketId)
          }}>Next</Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default Result;
