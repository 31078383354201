import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { Card, CardContent, CardActions, Typography, ListItem, ListItemText, ListItemButton } from '@mui/material';
import { FixedSizeList } from 'react-window'
import _ from 'lodash'

import { updateServerRecord } from './lib/googleSheet'
import AttendanceRecord from './AttendanceRecord';

function Setting (props) {
  const { events } = props
  const [event, setEvent] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const eventData = _.get(events, 'data', [])

  const renderRow = (props) => {
    const { index, style } = props;

    return (
      <ListItem style={style} key={index}>
        <ListItemButton onClick={() => setEvent(eventData[index])}>
          <ListItemText primary={eventData[index].title}/>
        </ListItemButton>
      </ListItem>
    );
  }

  const update = async() => {
    setLoading(true)
    await updateServerRecord()
    setLoading(false)
  }

  if (event) {
    return (
      <AttendanceRecord
        event={event}
        onBack={() => setEvent()}
      />
    )
  }

  return (
    <Card sx={{ width: '100%', height: '100vh' }}>
      <CardContent
        sx={{
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          overflowX: 'hidden'
        }}
      >
        <Typography variant="h5" component="div">
          Select Event
        </Typography>
        <FixedSizeList
          height={600}
          width={400}
          itemSize={65}
          itemCount={eventData.length}
          overscanCount={5}
        >
          
          {renderRow}
        </FixedSizeList>
        <CardActions sx={{ justifyContent: 'center' }}>
          <LoadingButton
            onClick={() => update()}
            loading={loading}
            loadingIndicator="Loading…"
            variant="contained"
          >
            upload
          </LoadingButton>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default Setting;
