import React from 'react';

import { Card, CardContent, CardActions, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment'

import Scanner from './Scanner'
import getEvents from './lib/events'
import Result from './Result'

function Main(props) {
  const { events, setEvents } = props
  const [page, setPage] = React.useState('home')
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = React.useState()

  React.useEffect(() => {
    getLocalStorage()
  }, [])

  if (data) {
    return (
      <Result
        data={data}
        events={events}
        onNext={() => setData()}
      />
    )
  }

  if (page === 'scanner') {
    return <Scanner onSuccess={(d) => setData(d)} />
  }

  const getLocalStorage = () => {
    try {
      const jsonData = localStorage.getItem('events')
      if (jsonData !== null) {
        const data = JSON.parse(jsonData)
        setEvents(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getEventData = async () => {
    setLoading(true)
    try {
      const data = await getEvents()
      const jsonData = JSON.stringify({ updatedAt: moment(), data })
      localStorage.setItem('events', jsonData)
      getLocalStorage()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card sx={{ width: '100%', height: '100vh' }}>
      <CardContent
        sx={{
          height: '80vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {
          Boolean(events)
            ? (
              <>
                <Typography variant="h6" component="div">
                  Event Data updated at:
                </Typography>
                <Typography component="div">
                  {moment(events.updatedAt).format('llll')}
                </Typography>
              </>
            ) : (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                color: 'red'
              }}>
                <span style={{ fontSize: '20px' }}>No Event Data</span>
                <CloseIcon fontSize="large" />
              </div>
            )
        }
        <CardActions sx={{ justifyContent: 'center' }}>
          <LoadingButton variant="contained" color="secondary" loading={loading} onClick={() => getEventData()}>Get Data</LoadingButton>
          {
            Boolean(events) && (
              <LoadingButton variant="contained" loading={loading} onClick={() => setPage('scanner')}>Scan</LoadingButton>
            )
          }
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default Main;
